import { CommonModule, isPlatformBrowser } from '@angular/common';
import { Component, EventEmitter, Inject, OnInit, Optional, Output } from '@angular/core';
import { FormControl, FormGroup, FormsModule, ReactiveFormsModule, Validators } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatCheckboxModule } from '@angular/material/checkbox';
import {
  MAT_DIALOG_DATA,
  MatDialogActions,
  MatDialogClose,
  MatDialogContent,
  MatDialogRef,
  MatDialogTitle,
} from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { ApiService } from '@services/api.service';
import { MatIconModule } from '@angular/material/icon';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { AuthService } from '@services/auth.service';
import { firstValueFrom } from 'rxjs';
import { SnackbarService } from '@services/snackbar.service';
import { Rent } from '@core/classes/rent';
import { RequestManager } from '@core/managers/RequestManager';
import { HeaderService } from '@services/header.service';
import { UserManager } from '@core/managers/UserManager';

@Component({
  selector: 'app-login',
  standalone: true,
  imports: [
    FormsModule,ReactiveFormsModule, MatFormFieldModule,MatInputModule,MatButtonModule,
    CommonModule,
    MatDialogTitle,
    MatDialogContent,
    MatDialogActions,
    MatDialogClose,
    MatCheckboxModule,
    MatIconModule,
    MatProgressBarModule,
  ],
  templateUrl: './login.component.html',
  styleUrl: './login.component.scss'
})
export class LoginComponent implements OnInit {



  formLogin : FormGroup = new FormGroup({
    email : new FormControl(null, [Validators.required]),
    password: new FormControl(null,[Validators.required])
  })

  loading : boolean = false;
  errorLogin : string = '';

  @Output()
  register_clicked : EventEmitter<boolean> = new EventEmitter();

  @Output()
  logged : EventEmitter<boolean> = new EventEmitter();

  id_google : string = "google_sign_in"
  constructor(
    @Optional() @Inject(MAT_DIALOG_DATA) private data : any,
    @Optional() public dialogRef: MatDialogRef<LoginComponent>,
    private snackbar : SnackbarService,
    private headerService: HeaderService,
    private requestManager : RequestManager,
    private userManager : UserManager){
      
      if(this.dialogRef){
        this.id_google += "_dialogref";
      }
  }
  ngOnInit(): void {
    
  }

  ngAfterViewInit(): void {

   
    if(isPlatformBrowser(this.requestManager.getPlatformID())){
      this.userManager.getAuth().buildButton(this.id_google, (credential : any)=>{
        if(credential){
          this.login(credential);
        }
      });
    }
   
  }

  setLoading ( l : boolean){
    this.loading = l;
  }

  getError(control: string) {
    let form : FormGroup = this.formLogin;
    const formControl : any = form.get(control)!;

    const errors : any = formControl.errors;
    if(errors?.required){
      return 'This field is required.';
    }
    if(errors?.[control] ?? false){
      return errors[control];
    }
    return '';
  }

  login(credential ?: any) {
    if(this.loading) return;
    
    this.userManager.login({
      credential : credential, 
      formLogin : credential ? undefined : this.formLogin,
      snackbar: this.snackbar,
      headerService : this.headerService,
      requestManager : this.requestManager,
      onloading : (l : boolean) => this.setLoading(l) , 
      dialogRef : this.dialogRef,
      data : this.data,
      logged : this.logged
    });
  }

  registerClick(event: any) {
    if(event){
      event.stopPropagation();
      event.preventDefault();
    }

    if(this.dialogRef){
      this.dialogRef.close({register: true});
      return;
    }

    this.register_clicked.emit(true);

  }
}
